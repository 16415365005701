import { NetworkStatus, useLazyQuery } from '@apollo/client';
import { useRouter } from 'next/router';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { useEffect } from 'react';

import styles from './styles.module.scss';

import Icon, { ICONS } from 'OK/components/icon';
import ContentLayout from 'OK/components/layouts/content';
import TextLayout from 'OK/components/layouts/content/text';
import PageTitle from 'OK/components/pageTitle';
import Text from 'OK/components/text';
import PublicProductPage from 'OK/modules/publicProductPage';
import { getItemByOKIDQuery } from 'OK/networking/items';
import { formatOkid, unformatOkid } from 'OK/util/formatting';
import { isOKID } from 'OK/util/functions/OKID';
import useI18n from 'OK/util/hooks/useI18n';

export default function ItemPage() {
  /* Variables */

  const { t } = useI18n();

  // Essential data

  const [getItemAPI, getItemAPIResult] = useLazyQuery(getItemByOKIDQuery, {
    errorPolicy: 'all',
  });
  const item = getItemAPIResult.data?.item;
  const product = item?.product;

  // State

  const apiCompleted =
    getItemAPIResult.called &&
    (getItemAPIResult.networkStatus === NetworkStatus.ready || getItemAPIResult.networkStatus === NetworkStatus.error);

  // Misc

  const router = useRouter();
  const OKID = router.query.OKID;

  /* Effects */

  // Redirect if not found
  useEffect(() => {
    if (apiCompleted && !item) {
      router.replace('/404');
    }
  }, [apiCompleted, item, router]);

  // Load product data
  useEffect(() => {
    if (OKID && isOKID(OKID)) {
      getItemAPI({ variables: { OKID: unformatOkid(OKID) } });
    }
  }, [OKID, getItemAPI]);

  // Reformat URL if necessary
  useEffect(() => {
    if (OKID) {
      const formattedOKID = formatOkid(OKID);
      if (!isOKID(formattedOKID)) {
        // Redirect to 404 due to invalid OKID
        router.replace('/404');
      } else if (formattedOKID !== OKID) {
        // Replace URL with formatted OKID
        router.replace(`/${formattedOKID}`);
      }
    }
  }, [OKID, router]);

  /* Render */

  if (item && product) {
    return <PublicProductPage OKID={OKID} item={item} product={product} />;
  } else if (apiCompleted) {
    return (
      <>
        <PageTitle>{t('ITEM_PAGE_ITEM_UNAVAILABLE_PAGE_TITLE')}</PageTitle>
        <ContentLayout className={styles.placeholderContainer} pageContent>
          <TextLayout>
            <h2>{t('ITEM_PAGE_ITEM_UNAVAILABLE_PAGE_TITLE')}</h2>
            <Text className={styles.unpublishedMessage}>
              {/* You cannot view this item because it is linked to an unpublished product. */}
              {t('ITEM_PAGE_ITEM_UNAVAILABLE_TEXT')}
            </Text>
          </TextLayout>
        </ContentLayout>
      </>
    );
  }

  return (
    <>
      <PageTitle>{t('PRODUCT')}</PageTitle>
      <ContentLayout className={styles.placeholderContainer}>
        <TextLayout>
          <Icon height={40} name={ICONS.SPINNER.name} width={40} />
        </TextLayout>
      </ContentLayout>
    </>
  );
}

ItemPage.layoutProps = {
  contentTopPadding: false,
  padFooter: false,
};

export async function getServerSideProps({ locale }) {
  const i18nProps = await serverSideTranslations(locale, ['common']);
  return { props: { ...i18nProps } };
}

